import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/start',
    name: 'start',
    alias: '/',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "start" */ '../views/StartPage.vue')
  },
  {
    path: '/step1',
    name: 'step1',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "step1" */ '../views/StepOne.vue')
  },
  {
    path: '/step2',
    name: 'step2',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "step2" */ '../views/StepTwo.vue')
  },
  {
    path: '/step3',
    name: 'step3',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "step3" */ '../views/StepThree.vue')
  },
  {
    path: '/step4',
    name: 'step4',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "step4" */ '../views/StepFour.vue')
  },
  {
    path: '/step5',
    name: 'step5',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "step5" */ '../views/StepFive.vue')
  },
  {
    path: '/step6',
    name: 'step6',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "step6" */ '../views/StepSix.vue')
  },
  {
    path: '/step7',
    name: 'step7',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "step7" */ '../views/StepSeven.vue')
  },
  {
    path: '/step8',
    name: 'step8',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "step8" */ '../views/StepEight.vue')
  },
  {
    path: '/step9',
    name: 'step9',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "step9" */ '../views/StepNine.vue')
  },
  {
    path: '/step10',
    name: 'step10',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "step10" */ '../views/StepTen.vue')
  },
  {
    path: '/step11',
    name: 'step11',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "step11" */ '../views/StepEleven.vue')
  },
  {
    path: '/step12',
    name: 'step12',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "step12" */ '../views/StepTwelve.vue')
  },
  {
    path: '/finish',
    name: 'finish',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "finish" */ '../views/FinishPage.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
